.referralLinks_content {
  .referralLinks_select .el-form {
    display: grid;
    grid-template-columns: repeat(auto-fill, 308px);
    column-gap: 2%;
  }

  .info_box {
    margin-top: 40px;

    > div {
      border-bottom: solid 1px $primary-purple;

      .labelTitle {
        height: 23px;
      }

      &:not(:last-child) {
        margin-bottom: 40px;
      }

      .main {
        height: calc(77px - 23px);

        p {
          font-size: 20px;
          font-weight: 200;
          line-height: 1.5;
          color: $primary-purple;
          display: inline-block;
          transform: translate(0%, 25%);
          padding: 0 5px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: calc(100% - 125px);
        }

        .form_button {
          @include rtl-sass-value(text-align, right, left);
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .referralLinks_content .info_box {
    padding: 48px 24px;
  }
}

@media (max-width: 550px) {
  .referralLinks_content {
    .referralLinks_select .el-form {
      grid-template-columns: repeat(auto-fill, minmax(auto, 300px));
      padding: 45px 24px 0;
    }

    .info_box {
      padding: 24px;
    }
  }
}
